import { useCallback, useEffect, useRef, useState } from "react";

import { getCoursesAssignedToCustomerInContext } from "./courses.service";
import { AssignedCourse, CourseFeedResponse } from "./courses.types";

type FetchCoursesParams = {
  searchQuery?: string;
  take?: number;
  skip?: number;
  setLoading: (loading: boolean) => void;
  setError: (error: boolean) => void;
  handleResponse: (response: CourseFeedResponse) => void;
};

const fetchCourses = async (params: FetchCoursesParams) => {
  const { searchQuery, skip, take, handleResponse, setLoading, setError } = params;
  setLoading(true);
  setError(false);
  try {
    const response = await getCoursesAssignedToCustomerInContext(
      searchQuery ?? "",
      skip ?? 0,
      take ?? 12
    );
    handleResponse(response);
  } catch (e) {
    setError(true);
  } finally {
    setLoading(false);
  }
};

export const useCourseFeed = <T extends HTMLElement>() => {
  const [courseFeed, setCourseFeed] = useState([] as AssignedCourse[]);
  const [fetchResponse, setFetchResponse] = useState<CourseFeedResponse|null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoading, setLoading] = useState(false);
  const [isAddingToFeed, setAddingToFeed] = useState(false);
  const [hasError, setError] = useState(false);
  const [totalCourseCount, setTotalCourseCount] = useState(0);
  const feedTriggerRef = useRef<T>(null);

  useEffect(() => {
    fetchCourses({
      searchQuery,
      skip: 0,
      setLoading,
      setError,
      handleResponse: setFetchResponse,
    });
  }, [searchQuery]);

  const observationHandler = useCallback((entries: IntersectionObserverEntry[]) => {
    if (isLoading || isAddingToFeed || !courseFeed.length) return;
    if (totalCourseCount <= courseFeed.length) return;
    if (entries.find((x) => x.isIntersecting)) {
      fetchCourses({
        searchQuery,
        skip: courseFeed.length,
        setLoading: setAddingToFeed,
        setError,
        handleResponse: setFetchResponse,
      });
    }
  }, [courseFeed]);

  useEffect(() => {
    if (!feedTriggerRef.current) return;
    const observer = new IntersectionObserver(observationHandler);
    observer.observe(feedTriggerRef.current);
    return () => observer.disconnect();
  }, [feedTriggerRef, isLoading, observationHandler]);

  // TODO: Consider defering adding to feed until thumbnails have been loaded
  // (Promise.all with img.onload).
  useEffect(() => {
    if (!fetchResponse) return;
    const isNewFeed = fetchResponse.from === 0;
    if (isNewFeed) {
      setCourseFeed(fetchResponse.courses);
      setTotalCourseCount(fetchResponse.total);
    } else {
      setCourseFeed([...courseFeed, ...fetchResponse.courses]);
    }
  }, [fetchResponse]);

  return {
    courseFeed,
    hasError,
    isLoading,
    isAddingToFeed,
    canFetchMore: courseFeed.length < totalCourseCount,
    setSearchQuery,
    totalCourseCount,
    visibleCourseCount: courseFeed.length,
    searchQuery,
    feedTriggerRef,
  };
};
