import { useState } from "react";
import { Course } from "../courses/courses.types";
import { useNavigate } from "react-router-dom";
import { classNames } from "../utils/classnames";

type CourseCardProps = {
    key:number;
    course: {
        course:Course;
    };
};

const THUMBNAIL_PLACEHOLDER = "/thumbnail-placeholder.png";

export const CourseCard: React.FC<CourseCardProps> = ({course}) => {
  const navigate = useNavigate();
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const handleCourseCardClick = () => {
    navigate(`/course-details/${course.course.id}`);
  };
  return <div className="cursor-pointer w-full rounded-lg shadow-lg overflow-hidden bg-white" onClick={handleCourseCardClick}>
    <img
      alt=""
      className={classNames(
        "h-60 md:h-auto w-full object-cover object-center",
        thumbnailLoaded ? "hidden" : "block"
      )}
      src={THUMBNAIL_PLACEHOLDER}
    />
    <img
      alt=""
      className={classNames(
        "h-40 md:h-auto w-full object-cover object-center",
        thumbnailLoaded ? "block" : "hidden"
      )}
      src={course.course.thumbnailUrl}
      onLoad={() => setThumbnailLoaded(true)}
    />
    <h1 className="text-base md:text-lg break-words mx-4 my-4">
      {course.course.title}
    </h1>
  </div>;
};

