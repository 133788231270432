import React, { FC } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { Loader } from "../loaders/loader.component";
import { CourseGrid } from "./course-grid";
import { useCourseFeed } from "./useCourseFeed";
import { CoursesHeader } from "./courses-header";
import { AssignedCourse } from "./courses.types";
import { GENERIC_ERROR_MESSAGE, NO_ACCESS_TOKEN } from "../@types/errors.types";
import { useAppToken } from "../app/hooks/useAppToken";
import { PageLayout } from "../components/Pages/PageLayout";


type CourseFeedProps = {
  feedTrigger: React.RefObject<HTMLDivElement>;
  courses: AssignedCourse[];
}

const CourseFeed: FC<CourseFeedProps> = (props) => {
  const { courses, feedTrigger } = props;
  return (
    <>
      <CourseGrid courses={courses} />
      <div
        ref={feedTrigger}
        className="mt-12"
      >&nbsp;</div>
    </>
  );
};

export const Courses: React.FC = () => {
  const { t } = useTranslation();
  const token = useAppToken();
  const navigate = useNavigate();
  if (!token) navigate(`/errors/${NO_ACCESS_TOKEN}`);

  const {
    courseFeed,
    isLoading,
    setSearchQuery,
    searchQuery,
    totalCourseCount,
    feedTriggerRef,
    hasError
  } = useCourseFeed<HTMLDivElement>();

  const title: JSX.Element | string =
    searchQuery && totalCourseCount ?
      <Trans i18nKey="courses.titleNCoursesFound" values={{ count: totalCourseCount }} />
      : searchQuery ? t`courses.titleNoCoursesFound`
        : t`courses.titleAllCourses`;

  const content =
    isLoading ? <Loader />
      : courseFeed.length ? <CourseFeed feedTrigger={feedTriggerRef} courses={courseFeed} />
        : <p className="text-3xl">{t`courses.bodyNoCoursesFound`}</p>;

  return (hasError ? <Navigate to={`/errors/${GENERIC_ERROR_MESSAGE}`} /> :
    <PageLayout>
      <CoursesHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        title={title}
      />
      {content}
    </PageLayout>
  );
};

