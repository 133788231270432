import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../components/Pages/PageTitle";
import { CourseSearch } from "./course-search";

export type CoursesHeaderProps = {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  title: JSX.Element | string;
}

export const CoursesHeader: FC<CoursesHeaderProps> = (props) => {
  const { t } = useTranslation();
  const { searchQuery, setSearchQuery, title } = props;
  return (
    <header className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-y-6">
      <div className="flex flex-row items-center gap-4">
        <PageTitle title={title} />
        { searchQuery &&
          <button
            onClick={() => setSearchQuery("")}
            className="border hover:bg-gray-300 rounded-md border-gray-700 h-6
              px-2 text-sm flex flex-row items-baseline gap-2 md:pt-[1px]"
          >
            {t`courses.clearSearchButton`} <img src="/cross.svg" alt="" className="h-2 inline" />
          </button>
        }
      </div>
      <CourseSearch onSearch={(x) => setSearchQuery(x)} />
    </header>
  );
};
